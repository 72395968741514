/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Seo from "../components/seo";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Figure from "react-bootstrap/Figure";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "bootstrap/dist/css/bootstrap.css";
import "./layout.scss";

const Layout = ({ children, title, titleTemplate, keywords, description }) => (
  <StaticQuery
    query={graphql`
      query {
        logoImage: file(relativePath: { eq: "cerco_logo.png" }) {
          childImageSharp {
            fixed(height: 55) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cicsImage: file(relativePath: { eq: "cics.png" }) {
          childImageSharp {
            fixed(width: 147) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Seo title={title} titleTemplate={titleTemplate} keywords={keywords} description={description} />
        <header className="mb-5">
          <Container>
            <Navbar expand="lg" variant="dark">
              <Navbar.Brand href="/">
                <Img
                  fixed={data.logoImage.childImageSharp.fixed}
                  alt="Cerco Corporation"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href="/structural-components/">
                    Structural Components
                  </Nav.Link>
                  <Nav.Link href="/grinding-media/">Grinding Media</Nav.Link>
                  <Nav.Link href="/kiln-and-heating/">
                    Kiln and Heating
                  </Nav.Link>
                  <Nav.Link href="/wear-resistant-solutions/">
                    Wear Resistant Solutions
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </header>
        <main>{children}</main>
        <footer className="pt-5 pb-2 mt-5">
          <Container>
            <Row>
              <Col sm={6} md={3}>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/structural-components">
                      Structural Components
                    </Link>
                  </li>
                  <li>
                    <Link to="/grinding-media">Grinding Media</Link>
                  </li>
                  <li>
                    <Link to="/kiln-and-heating">Kiln and Heating</Link>
                  </li>
                  <li>
                    <Link to="/wear-resistant-solutions">
                      Wear Resistant Solutions
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col sm={6} md={3}>
                <address>
                  <strong>Cesco Plant</strong>
                  <br />
                  416 Maple Avenue
                  <br />
                  Crooksville, Ohio 43731
                  <br />
                  Tel: (740) 982-2050
                  <br />
                  Fax: (740) 982-9396
                  <br />
                </address>
              </Col>
              <Col sm={6} md={3}>
                <address>
                  <strong>Diamonite Plant</strong>
                  <br />
                  453 West McConkey Street
                  <br />
                  Shreve, Ohio 44676
                  <br />
                  Tel: (330) 567-2145
                  <br />
                  Fax: (330) 915-4925
                  <br />
                  <small>ISO 9001:2008 Certified</small>
                </address>
              </Col>
              <Col sm={6} md={3}>
                <Figure>
                  <Img
                    fixed={data.cicsImage.childImageSharp.fixed}
                    alt="Cics"
                    className="figure-img"
                  />
                </Figure>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <small>
                      <Link to="/terms-and-conditions">
                        Terms &amp; Conditions
                      </Link>
                    </small>
                  </li>
                  <li className="list-inline-item">
                    <small>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </small>
                  </li>
                  <li className="list-inline-item">
                    <small>
                      <span>
                        &copy; {new Date().getFullYear()} CerCo Corporation
                      </span>
                    </small>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  titleTemplate: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string)
};

export default Layout;
